var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"home-btn d-none d-sm-block"},[_c('router-link',{staticClass:"text-dark",attrs:{"to":"/"}},[_c('i',{staticClass:"mdi mdi-home-variant h2"})])],1),_c('div',{staticClass:"account-pages my-5 pt-sm-5"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"text-center"},[_c('router-link',{staticClass:"mb-5 d-block auth-logo",attrs:{"to":"/"}},[_c('img',{staticClass:"logo logo-dark",attrs:{"src":require("@/assets/images/logo-dark.png"),"alt":"","height":"22"}}),_c('img',{staticClass:"logo logo-light",attrs:{"src":require("@/assets/images/logo-light.png"),"alt":"","height":"22"}})])],1)])]),_c('div',{staticClass:"row align-items-center justify-content-center"},[_c('div',{staticClass:"col-md-8 col-lg-6 col-xl-5"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body p-4"},[_vm._m(0),_c('div',{staticClass:"p-2 mt-4"},[_c('form',[_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_c('div',{staticClass:"mt-4 text-center"},[_c('p',{staticClass:"text-muted mb-0"},[_vm._v(" Already have an account ? "),_c('router-link',{staticClass:"fw-medium text-primary",attrs:{"to":"/auth/login-1"}},[_vm._v("Login")])],1)])])])])]),_c('div',{staticClass:"mt-5 text-center"},[_c('p',[_vm._v(" © "+_vm._s(new Date().getFullYear())+" SuriyaIT. ")])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center mt-2"},[_c('h5',{staticClass:"text-primary"},[_vm._v("Register Account")]),_c('p',{staticClass:"text-muted"},[_vm._v("Get your free Minible account now.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-3"},[_c('label',{attrs:{"for":"useremail"}},[_vm._v("Email")]),_c('input',{staticClass:"form-control",attrs:{"type":"email","id":"useremail","placeholder":"Enter email"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-3"},[_c('label',{attrs:{"for":"username"}},[_vm._v("Username")]),_c('input',{staticClass:"form-control",attrs:{"type":"text","id":"username","placeholder":"Enter username"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-3"},[_c('label',{attrs:{"for":"userpassword"}},[_vm._v("Password")]),_c('input',{staticClass:"form-control",attrs:{"type":"password","id":"userpassword","placeholder":"Enter password"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-check"},[_c('input',{staticClass:"form-check-input",attrs:{"type":"checkbox","id":"auth-terms-condition-check"}}),_c('label',{staticClass:"form-check-label",attrs:{"for":"auth-terms-condition-check"}},[_vm._v("I accept "),_c('a',{staticClass:"text-dark",attrs:{"href":"javascript: void(0);"}},[_vm._v("Terms and Conditions")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-3 text-end"},[_c('button',{staticClass:"btn btn-primary w-sm waves-effect waves-light",attrs:{"type":"submit"}},[_vm._v(" Register ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-4 text-center"},[_c('div',{staticClass:"signin-other-title"},[_c('h5',{staticClass:"font-size-14 mb-3 title"},[_vm._v("Sign up using")])]),_c('ul',{staticClass:"list-inline"},[_c('li',{staticClass:"list-inline-item"},[_c('a',{staticClass:"social-list-item bg-primary text-white border-primary",attrs:{"href":"javascript:void()"}},[_c('i',{staticClass:"mdi mdi-facebook"})])]),_c('li',{staticClass:"list-inline-item"},[_c('a',{staticClass:"social-list-item bg-info text-white border-info",attrs:{"href":"javascript:void()"}},[_c('i',{staticClass:"mdi mdi-twitter"})])]),_c('li',{staticClass:"list-inline-item"},[_c('a',{staticClass:"social-list-item bg-danger text-white border-danger",attrs:{"href":"javascript:void()"}},[_c('i',{staticClass:"mdi mdi-google"})])])])])
}]

export { render, staticRenderFns }